<template>
  <main>
    <template v-if="objects.list.length">
      <h1 class="heading" v-if="!$store.state.labelOpen&&!$store.state.isMobile">Labels</h1>
      <h1 class="heading" v-if="$store.state.labelOpen&&!$store.state.isMobile">Edit Label</h1>
      <div v-if="!$store.state.labelOpen" class="event_header" style="margin-bottom:15px;width:100%;">
        <div style="display: flex">
          <div style="flex:1;margin-right:15px;">
            <v-select
                v-model="objectItem"
                hide-details
                :items="objects.list"
                item-value="id"
                item-text="name"
                return-object
                dense
                style="max-width: 300px"
                outlined
            >
              <template v-slot:selection="{ item }">
                <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
                <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
                <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
              </template>
              <template v-slot:item="{ item }">
                <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
                <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
                <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
              </template>
            </v-select>
          </div>
          <div style="flex:1;display: flex;justify-content: flex-end">
            <v-btn color="primary" outlined dense small @click="openDialog"
            >+ Add label</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="!$store.state.labelOpen" class="event_body">
        <div style="overflow:hidden;width:100%;">
          <swiper
              ref="mySwiper"
              :options="swiperOptionsListing"
              style="max-width: 280px;min-height:381px;"
          >
            <swiper-slide v-for="label in labels" :key="label.id" style="overflow: hidden;">
              <div :ref="`ref_${label.qr}`" style="min-height:381px;border:1px solid #e4e4e4;border-radius: 15px;padding:15px;margin-bottom: 15px;">
                <div style="width:250px;height:326px;scale:0.5;cursor:pointer;" :style="`background-color:${label.styles.bg};`" @click="openEdit(label)">
                  <div style="height:100%;padding:15px;">
                    <div style="margin:0 auto 30px auto;width:100%;">
                      <div v-html="label.header_text"></div>
                    </div>
                    <div style="width:100%;margin-bottom:30px;">
                      <qr-code
                          id="qr_code"
                          :text="qrLink"
                          style="margin:0 auto;"
                          :style="`max-width:100%;width:${label.styles.qr.size}px;height:${label.styles.qr.size}px;`"
                          :size="label.styles.qr.size"
                          :color="label.styles.qr.color"
                          :bg-color="label.styles.qr.bg"
                          error-level="H"
                      ></qr-code>
                    </div>
                    <div style="width:100%;">
                      <div v-html="label.bottom_text"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display:flex;justify-content:space-between">
                <v-btn color="primary" outlined small @click="download(label)">
                  <v-icon
                      color="#007db5"
                      style="margin-right: 5px"
                  >mdi-download</v-icon
                  >Download
                </v-btn>
                <v-menu offset-y open-on-hover bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                  <v-list-item link>
                    <v-list-item-title style="cursor: pointer;display:flex;align-items:center;" @click="openEdit(label)">
                      <v-icon
                          color="#007db5"
                          style="margin-right: 5px"
                      >mdi-pencil</v-icon
                      >Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                  <v-list-item-title style="cursor: pointer;color:red;display:flex;align-items:center;" @click="openDelete(label)">
                    <v-icon color="red"
                    >mdi-delete</v-icon
                    >Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </template>
    <ObjectsEmpty title="Labels" v-else />
    <div>
      <LabelPopup @create="createLabel" @edit="editLabel" v-if="$store.state.labelOpen" :labelShow="$store.state.labelOpen" @closeLabelDialog="closeDialog" :objectItem="objectItem" :type="type" :label="selectedLabel"/>
    </div>
    <PopupDelete
        ref="PopupDelete"
        :title="`Are you sure about delete ?`"
        @confirm="deleteItemConfirm"
    />
  </main>
</template>
<script lang="js">
import store from "@/store";
import {mapState} from "vuex";
import liteLabelsService from "@/services/liteLabels.service";
import "swiper/swiper-bundle.css";
import "swiper/modules/effect-flip/effect-flip.scss";
import "swiper/modules/effect-flip/effect-flip.js";
import axios from 'axios';
import HotelService from "@/services/hotel.service"
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name:"Labels",
  beforeRouteEnter (to, from, next) {
    if(!store.state.objects.list.length){
      store.dispatch('objects/getList')
          .then((data) => {
            next();
          }).catch(() => {
        next();
      })
    }else{
      next();
    }
  },
  data(){
    return{
      countries: [
        { value: "AF", label: "Afghanistan" },
        { value: "AX", label: "Åland Islands" },
        { value: "AL", label: "Albania" },
        { value: "DZ", label: "Algeria" },
        { value: "AS", label: "American Samoa" },
        { value: "AD", label: "Andorra" },
        { value: "AO", label: "Angola" },
        { value: "AI", label: "Anguilla" },
        { value: "AQ", label: "Antarctica" },
        { value: "AG", label: "Antigua and Barbuda" },
        { value: "AR", label: "Argentina" },
        { value: "AM", label: "Armenia" },
        { value: "AW", label: "Aruba" },
        { value: "AU", label: "Australia" },
        { value: "AT", label: "Austria" },
        { value: "AZ", label: "Azerbaijan" },
        { value: "BS", label: "Bahamas" },
        { value: "BH", label: "Bahrain" },
        { value: "BD", label: "Bangladesh" },
        { value: "BB", label: "Barbados" },
        { value: "BY", label: "Belarus" },
        { value: "BE", label: "Belgium" },
        { value: "BZ", label: "Belize" },
        { value: "BJ", label: "Benin" },
        { value: "BM", label: "Bermuda" },
        { value: "BT", label: "Bhutan" },
        { value: "BO", label: "Bolivia, Plurinational State of" },
        { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
        { value: "BA", label: "Bosnia and Herzegovina" },
        { value: "BW", label: "Botswana" },
        { value: "BV", label: "Bouvet Island" },
        { value: "BR", label: "Brazil" },
        { value: "IO", label: "British Indian Ocean Territory" },
        { value: "BN", label: "Brunei Darussalam" },
        { value: "BG", label: "Bulgaria" },
        { value: "BF", label: "Burkina Faso" },
        { value: "BI", label: "Burundi" },
        { value: "KH", label: "Cambodia" },
        { value: "CM", label: "Cameroon" },
        { value: "CA", label: "Canada" },
        { value: "CV", label: "Cape Verde" },
        { value: "KY", label: "Cayman Islands" },
        { value: "CF", label: "Central African Republic" },
        { value: "TD", label: "Chad" },
        { value: "CL", label: "Chile" },
        { value: "CN", label: "China" },
        { value: "CX", label: "Christmas Island" },
        { value: "CC", label: "Cocos (Keeling) Islands" },
        { value: "CO", label: "Colombia" },
        { value: "KM", label: "Comoros" },
        { value: "CG", label: "Congo" },
        { value: "CD", label: "Congo, the Democratic Republic of the" },
        { value: "CK", label: "Cook Islands" },
        { value: "CR", label: "Costa Rica" },
        { value: "CI", label: "Côte d'Ivoire" },
        { value: "HR", label: "Croatia" },
        { value: "CU", label: "Cuba" },
        { value: "CW", label: "Curaçao" },
        { value: "CY", label: "Cyprus" },
        { value: "CZ", label: "Czech Republic" },
        { value: "DK", label: "Denmark" },
        { value: "DJ", label: "Djibouti" },
        { value: "DM", label: "Dominica" },
        { value: "DO", label: "Dominican Republic" },
        { value: "EC", label: "Ecuador" },
        { value: "EG", label: "Egypt" },
        { value: "SV", label: "El Salvador" },
        { value: "GQ", label: "Equatorial Guinea" },
        { value: "ER", label: "Eritrea" },
        { value: "EE", label: "Estonia" },
        { value: "ET", label: "Ethiopia" },
        { value: "FK", label: "Falkland Islands (Malvinas)" },
        { value: "FO", label: "Faroe Islands" },
        { value: "FJ", label: "Fiji" },
        { value: "FI", label: "Finland" },
        { value: "FR", label: "France" },
        { value: "GF", label: "French Guiana" },
        { value: "PF", label: "French Polynesia" },
        { value: "TF", label: "French Southern Territories" },
        { value: "GA", label: "Gabon" },
        { value: "GM", label: "Gambia" },
        { value: "GE", label: "Georgia" },
        { value: "DE", label: "Germany" },
        { value: "GH", label: "Ghana" },
        { value: "GI", label: "Gibraltar" },
        { value: "GR", label: "Greece" },
        { value: "GL", label: "Greenland" },
        { value: "GD", label: "Grenada" },
        { value: "GP", label: "Guadeloupe" },
        { value: "GU", label: "Guam" },
        { value: "GT", label: "Guatemala" },
        { value: "GG", label: "Guernsey" },
        { value: "GN", label: "Guinea" },
        { value: "GW", label: "Guinea-Bissau" },
        { value: "GY", label: "Guyana" },
        { value: "HT", label: "Haiti" },
        { value: "HM", label: "Heard Island and McDonald Mcdonald Islands" },
        { value: "VA", label: "Holy See (Vatican City State)" },
        { value: "HN", label: "Honduras" },
        { value: "HK", label: "Hong Kong" },
        { value: "HU", label: "Hungary" },
        { value: "IS", label: "Iceland" },
        { value: "IN", label: "India" },
        { value: "ID", label: "Indonesia" },
        { value: "IR", label: "Iran, Islamic Republic of" },
        { value: "IQ", label: "Iraq" },
        { value: "IE", label: "Ireland" },
        { value: "IM", label: "Isle of Man" },
        { value: "IL", label: "Israel" },
        { value: "IT", label: "Italy" },
        { value: "JM", label: "Jamaica" },
        { value: "JP", label: "Japan" },
        { value: "JE", label: "Jersey" },
        { value: "JO", label: "Jordan" },
        { value: "KZ", label: "Kazakhstan" },
        { value: "KE", label: "Kenya" },
        { value: "KI", label: "Kiribati" },
        { value: "KP", label: "Korea, Democratic People's Republic of" },
        { value: "KR", label: "Korea, Republic of" },
        { value: "KW", label: "Kuwait" },
        { value: "KG", label: "Kyrgyzstan" },
        { value: "LA", label: "Lao People's Democratic Republic" },
        { value: "LV", label: "Latvia" },
        { value: "LB", label: "Lebanon" },
        { value: "LS", label: "Lesotho" },
        { value: "LR", label: "Liberia" },
        { value: "LY", label: "Libya" },
        { value: "LI", label: "Liechtenstein" },
        { value: "LT", label: "Lithuania" },
        { value: "LU", label: "Luxembourg" },
        { value: "MO", label: "Macao" },
        { value: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
        { value: "MG", label: "Madagascar" },
        { value: "MW", label: "Malawi" },
        { value: "MY", label: "Malaysia" },
        { value: "MV", label: "Maldives" },
        { value: "ML", label: "Mali" },
        { value: "MT", label: "Malta" },
        { value: "MH", label: "Marshall Islands" },
        { value: "MQ", label: "Martinique" },
        { value: "MR", label: "Mauritania" },
        { value: "MU", label: "Mauritius" },
        { value: "YT", label: "Mayotte" },
        { value: "MX", label: "Mexico" },
        { value: "FM", label: "Micronesia, Federated States of" },
        { value: "MD", label: "Moldova, Republic of" },
        { value: "MC", label: "Monaco" },
        { value: "MN", label: "Mongolia" },
        { value: "ME", label: "Montenegro" },
        { value: "MS", label: "Montserrat" },
        { value: "MA", label: "Morocco" },
        { value: "MZ", label: "Mozambique" },
        { value: "MM", label: "Myanmar" },
        { value: "NA", label: "Namibia" },
        { value: "NR", label: "Nauru" },
        { value: "NP", label: "Nepal" },
        { value: "NL", label: "Netherlands" },
        { value: "NC", label: "New Caledonia" },
        { value: "NZ", label: "New Zealand" },
        { value: "NI", label: "Nicaragua" },
        { value: "NE", label: "Niger" },
        { value: "NG", label: "Nigeria" },
        { value: "NU", label: "Niue" },
        { value: "NF", label: "Norfolk Island" },
        { value: "MP", label: "Northern Mariana Islands" },
        { value: "NO", label: "Norway" },
        { value: "OM", label: "Oman" },
        { value: "PK", label: "Pakistan" },
        { value: "PW", label: "Palau" },
        { value: "PS", label: "Palestine, State of" },
        { value: "PA", label: "Panama" },
        { value: "PG", label: "Papua New Guinea" },
        { value: "PY", label: "Paraguay" },
        { value: "PE", label: "Peru" },
        { value: "PH", label: "Philippines" },
        { value: "PN", label: "Pitcairn" },
        { value: "PL", label: "Poland" },
        { value: "PT", label: "Portugal" },
        { value: "PR", label: "Puerto Rico" },
        { value: "QA", label: "Qatar" },
        { value: "RE", label: "Réunion" },
        { value: "RO", label: "Romania" },
        { value: "RU", label: "Russian Federation" },
        { value: "RW", label: "Rwanda" },
        { value: "BL", label: "Saint Barthélemy" },
        { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
        { value: "KN", label: "Saint Kitts and Nevis" },
        { value: "LC", label: "Saint Lucia" },
        { value: "MF", label: "Saint Martin (French part)" },
        { value: "PM", label: "Saint Pierre and Miquelon" },
        { value: "VC", label: "Saint Vincent and the Grenadines" },
        { value: "WS", label: "Samoa" },
        { value: "SM", label: "San Marino" },
        { value: "ST", label: "Sao Tome and Principe" },
        { value: "SA", label: "Saudi Arabia" },
        { value: "SN", label: "Senegal" },
        { value: "RS", label: "Serbia" },
        { value: "SC", label: "Seychelles" },
        { value: "SL", label: "Sierra Leone" },
        { value: "SG", label: "Singapore" },
        { value: "SX", label: "Sint Maarten (Dutch part)" },
        { value: "SK", label: "Slovakia" },
        { value: "SI", label: "Slovenia" },
        { value: "SB", label: "Solomon Islands" },
        { value: "SO", label: "Somalia" },
        { value: "ZA", label: "South Africa" },
        { value: "GS", label: "South Georgia and the South Sandwich Islands" },
        { value: "SS", label: "South Sudan" },
        { value: "ES", label: "Spain" },
        { value: "LK", label: "Sri Lanka" },
        { value: "SD", label: "Sudan" },
        { value: "SR", label: "Suriname" },
        { value: "SJ", label: "Svalbard and Jan Mayen" },
        { value: "SZ", label: "Swaziland" },
        { value: "SE", label: "Sweden" },
        { value: "CH", label: "Switzerland" },
        { value: "SY", label: "Syrian Arab Republic" },
        { value: "TW", label: "Taiwan, Province of China" },
        { value: "TJ", label: "Tajikistan" },
        { value: "TZ", label: "Tanzania, United Republic of" },
        { value: "TH", label: "Thailand" },
        { value: "TL", label: "Timor-Leste" },
        { value: "TG", label: "Togo" },
        { value: "TK", label: "Tokelau" },
        { value: "TO", label: "Tonga" },
        { value: "TT", label: "Trinidad and Tobago" },
        { value: "TN", label: "Tunisia" },
        { value: "TR", label: "Turkey" },
        { value: "TM", label: "Turkmenistan" },
        { value: "TC", label: "Turks and Caicos Islands" },
        { value: "TV", label: "Tuvalu" },
        { value: "UG", label: "Uganda" },
        { value: "UA", label: "Ukraine" },
        { value: "AE", label: "United Arab Emirates" },
        { value: "GB", label: "United Kingdom" },
        { value: "US", label: "United States" },
        { value: "UM", label: "United States Minor Outlying Islands" },
        { value: "UY", label: "Uruguay" },
        { value: "UZ", label: "Uzbekistan" },
        { value: "VU", label: "Vanuatu" },
        { value: "VE", label: "Venezuela, Bolivarian Republic of" },
        { value: "VN", label: "Viet Nam" },
        { value: "VG", label: "Virgin Islands, British" },
        { value: "VI", label: "Virgin Islands, U.S." },
        { value: "WF", label: "Wallis and Futuna" },
        { value: "EH", label: "Western Sahara" },
        { value: "YE", label: "Yemen" },
        { value: "ZM", label: "Zambia" },
        { value: "ZW", label: "Zimbabwe" },
      ],
      swiperOptionsListing: {
        grabCursor: true,
        centeredSlides: true,
        spaceBetween: 15,
        hide: true,
        effect: "flip",
      },
      headers: [
        { text: "Image", value: "main_image", align: "center", sortable: true },
        { text: "Header", value: "name", align: "center", sortable: false },
        { text: "Options", align: "center", sortable: false },
      ],
      categoryType:"Create",
      dialogCategory:false,
      defaultCategory:{
        name:"",
        image:null,
        hotel_id:null,
      },
      selectedCategory:null,
      objectItem:null,
      deleteItem:null,
      type:"Create",
      labels:[],
      selectedLabel:null,
      defaultLabel:{
        header_text:'<p style="text-align: center;"><span style="font-size: 18pt; font-family: georgia, palatino, serif;">Welcome</span></p>',
        styles:{
          bg:"#fff",
          qr:{
            color:"#000",
            bg:"#fff",
            size:128
          }
        },
        qr:"",
        bottom_text:'<p style="text-align: center;"><span style="font-size: 18pt; font-family: terminal, monaco, monospace;">Scan me to </span></p>\n' +
            '<p style="text-align: center;"><span style="font-size: 18pt; font-family: terminal, monaco, monospace;">view our app!</span></p>'
      }
    }
  },
  destroyed() {
    this.$store.state.labelOpen = false;
  },
  components:{
    ObjectsEmpty:() => import('@/components/ObjectsEmpty'),
    LabelPopup:() => import("@/components/Labels/LabelPopup"),
    PopupDelete:() => import("@/components/Popup/PopupDelete"),
    Swiper, SwiperSlide
  },
  methods:{
    async download(label){
      const wrapper = this.$refs[`ref_${label.qr}`][0];
      const img = wrapper.getElementsByTagName('canvas')[0].toDataURL("image/png");
      const html = {
        html:
            `${wrapper.innerHTML}`
      };
      const updateSize = html.html.replace('250','700').replace('326','987');
      const updateImage = updateSize.indexOf('src')===-1?updateSize.replace('alt="Scan me!"','alt="Scan me!" src="'+img+'"').replace('none !important;','block !important;width:'+label.styles.qr.size+'px;height:'+label.styles.qr.size+'px;'):updateSize;
      const sendHtml = {
        html:updateImage,
        hotel_id: this.objectItem.id,
        sticker_id: label.id,
      }
      const data = await HotelService.getHtmlLink(sendHtml)
      const sendPdf = {
        html:data,
        hotel_id: this.objectItem.id,
        sticker_id: label.id,
      }
      const pdf = await HotelService.getPdfLinkFromHtmlLink(sendPdf);
      const link = document.createElement("a");
      link.download = `${this.objectItem.name}_${this.$moment().format('DD.MM.YYYY HH:mm')}.pdf`;
      link.href = pdf
      link.target = "_blank"
      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      link.dispatchEvent(evt);
      link.remove()
    },
    changeFormatLangPic(lang) {
      const langArr = lang.split("-");
      if (langArr[1]) {
        const candidate = this.countries.find(
            (x) => x.value === langArr[1].toUpperCase()
        );
        if (candidate) {
          return candidate.value;
        }
      } else {
        if(lang === 'en'){
          return 'GB';
        }
        return lang.toUpperCase();
      }
    },
    async deleteItemConfirm(){
      const deletedLabel = await liteLabelsService.deleteLabel(this.deleteItem.id);
      const candidate = this.labels.find(x => x.id === deletedLabel.id);
      if(candidate){
        const candidateIndex = this.labels.indexOf(candidate);
        if(candidateIndex>=0){
          this.labels.splice(candidateIndex,1);
        }
      }
    },
    openDelete(label){
      this.deleteItem = JSON.parse(JSON.stringify(label));
      this.$refs.PopupDelete.open()
    },
    editLabel(label){
      const candidate = this.labels.find(x => x.id === label.id);
      if(candidate){
        const candidateIndex = this.labels.indexOf(candidate);
        if(candidateIndex>=0){
          this.labels.splice(candidateIndex,1,label);
        }
      }
    },
    openEdit(label){
      this.selectedLabel = JSON.parse(JSON.stringify(label));
      this.type = 'Edit';
      this.$store.state.labelOpen = true;
      this.$store.state.labelOpen = true;
    },
    createLabel(label){
      this.labels.push(label);
    },
    async openDialog(){
      this.type = 'Edit';
      let label = JSON.parse(JSON.stringify(this.defaultLabel));
      label.qr = this.objectItem.qr;
      label.hotel_id = this.objectItem.id;
      this.selectedLabel = await liteLabelsService.postLabel(label);
      this.createLabel(this.selectedLabel);
      this.$store.state.labelOpen = true;
      this.$store.state.labelOpen = true;
    },
    closeDialog(){
      this.$store.state.labelOpen = false;
      this.$store.state.labelOpen = false;
    }
  },
  computed:{
    ...mapState(['objects']),
    qrLink(){
      return 'https://guest.eco/'+this.objectItem.id;
    }
  },
  async created(){
    if (this.objects.list.length) {
      if (this.$route.query.id) {
        const candidate = this.objects.list.find(
            (x) => x.id === +this.$route.query.id
        );
        if (candidate) {
          this.objectItem = candidate;
        } else {
          this.objectItem = this.objects.list[0];
        }
      } else {
        this.objectItem = this.objects.list[0];
      }
      this.labels = await liteLabelsService.getLabelsByHotelId(this.objectItem.id);
      console.log(this.labels)
    }
    document.title = 'Labels'
  }
}
</script>
<style lang="scss">
.tariff_obj {
  margin-left:5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
#qr_code canvas {
  max-width:100% !important;
}
</style>
